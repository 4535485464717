import React, { createContext, useState, useEffect } from "react";
import { centroid } from "@turf/centroid";

export const GeoJsonDataContext = createContext();

const GeoJsonDataProvider = ({ children }) => {
  const [geoJsonData, setGeoJsonData] = useState(null);

  useEffect(() => {
    fetch("/data.geojson")
      .then((response) => response.json())
      .then((data) => {
        const dataWithCentroids = data.features.map((feature) => {
          // console.log(feature.geometry.coordinates);
          const featureCentroid = feature.geometry ? centroid(feature) : null;
          feature.properties.centroid = featureCentroid
            ? featureCentroid.geometry.coordinates
            : null;
          return feature;
        });
        setGeoJsonData({
          type: "FeatureCollection",
          features: dataWithCentroids,
        });
      })
      .catch((error) => console.error("Error loading GeoJSON data:", error));
  }, []);

  return (
    <GeoJsonDataContext.Provider value={geoJsonData}>
      {children}
    </GeoJsonDataContext.Provider>
  );
};

export default GeoJsonDataProvider;
