import React, { createContext, useState, useEffect, useContext } from "react";
import { GeoJsonDataContext } from "./GeoJsonDataContext";
import { SalariesContext } from "./SalariesContext";
import { FilterContext } from "./FilterContext";
// import { CENSUS_YEARS } from "../config";
import {
  calculateAffordability,
  calculateShortage,
  findBinForValue,
} from "../utils";

export const DataContext = createContext();

const DataProvider = ({ children }) => {
  const [affordabilityData, setAffordabilityData] = useState(null);
  const [shortageData, setShortageData] = useState(null);

  const geoJsonData = useContext(GeoJsonDataContext);
  const salariesData = useContext(SalariesContext);
  const { filters } = useContext(FilterContext);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (
      geoJsonData &&
      salariesData &&
      filters.location &&
      filters.year &&
      filters.cost &&
      filters.propertyCategory &&
      filters.income
    ) {
      const enrichedGeoJson = {
        ...geoJsonData,
        features: geoJsonData.features
          .filter((feature) => feature.geometry && feature.geometry.coordinates)
          .map((feature) => {
            const salaries = salariesData.filter((d) =>
              feature.properties.STE_NAME21
                ? d.State === feature.properties.STE_NAME21
                : false
            );
            return {
              ...feature,
              properties: {
                ...feature.properties,
                affordability:
                  salaries.length > 0
                    ? calculateAffordability(
                        feature,
                        salaries,
                        filters,
                        filters.year
                      )
                    : null,
                shortage:
                  salaries.length > 0
                    ? calculateShortage(feature, filters, filters.year)
                    : null,
              },
            };
          }),
      };
      setAffordabilityData(enrichedGeoJson);
    }
  }, [geoJsonData, salariesData, filters]);

  useEffect(() => {
    if (affordabilityData) {
      const shortageValues = affordabilityData.features.map((f) => {
        return f.properties.shortage;
      });
      // Sort the array
      const sortedValues = [...shortageValues].sort((a, b) => a - b);
      setShortageData({
        type: "FeatureCollection",
        features: affordabilityData.features.map((feature) => ({
          type: "Feature",
          properties: feature.properties
            ? {
                ...feature.properties,
                shortageClass: feature.properties.shortage
                  ? findBinForValue(sortedValues, feature.properties.shortage)
                  : null,
              }
            : {},
          geometry: {
            type: "Point",
            coordinates: feature.properties.centroid
              ? feature.properties.centroid
              : [],
          },
        })),
      });
      setIsLoading(false);
    }
  }, [affordabilityData]);

  const contextValue = {
    affordabilityData,
    shortageData,
    isLoading,
  };

  return (
    <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>
  );
};

export default DataProvider;
