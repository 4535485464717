import React, { createContext, useState } from "react";
import { END_YEAR } from "../config";

export const FilterContext = createContext();

const FilterProvider = ({ children }) => {
  const [filters, setFilters] = useState({
    year: END_YEAR,
    location: null,
    cost: null,
    propertyCategory: null,
    income: null,
    demand: null,
    supply: null,
  });

  const updateFilter = (column, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [column]: value,
    }));
  };

  return (
    <FilterContext.Provider value={{ filters, updateFilter }}>
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;
