export const START_YEAR = "2011";
export const END_YEAR = "2023";
export const CENSUS_YEARS = ["2011", "2016", "2021"];

// Rent
// Affordable: 3.0 & under
// Moderately unaffordable: 3.1 – 4.0
// Seriously unaffordable: 4.1 – 5.0
// Severely unaffordable: 5.1 & over

// Buy
// Affordable: 15% or less
// Acceptable: 20-25%
// Moderately unaffordable: 25-30%
// Unaffordable: 30-38%
// Severely unaffordable: 38-60%
// Extremely unaffordable: 60% or more
export const AFFORDABILITY_THRESHOLDS = {
  buy: {
    Affordable: 0,
    "Moderately unaffordable": 3,
    "Seriously unaffordable": 4,
    "Severely unaffordable": 5,
  },
  rent: {
    Affordable: 0,
    Acceptable: 0.2,
    "Moderately unaffordable": 0.25,
    Unaffordable: 0.3,
    "Severely unaffordable": 0.38,
    "Extremely unaffordable": 0.6,
  },
};

export const AFFORDABILITY_COLOURS = {
  buy: {
    Affordable: "#849DEA",
    "Moderately unaffordable": "#FFBEBB",
    "Seriously unaffordable": "#A30700",
    "Severely unaffordable": "#67000D",
  },
  rent: {
    Affordable: "#849DEA",
    Acceptable: "#E2E7F6",
    "Moderately unaffordable": "#FFBEBB",
    Unaffordable: "#E05151",
    "Severely unaffordable": "#A30700",
    "Extremely unaffordable": "#67000D",
  },
};

export const SHORTAGE_COLOURS = {
  "Much lower": "#A7A7A7",
  Lower: "#C5C5C5",
  Normal: "#efefef",
  Higher: "#FF9FA1",
  "Much higher": "#FF484B",
};

export const GLOSSARY = {
  affordability: {
    rent: "Renting becomes unaffordable if price is more than 25% of weekly income.",
    buy: "Buying becomes unaffordable if price is more than 3 times the annual income.",
  },
  shortage:
    "Shortage is higher if demand:supply ratio is in the fourth quartile.",
  affordabilityClasses: {
    buy: {
      Affordable: "3 times of annual income",
      "Moderately unaffordable": "4 times of annual income",
      "Seriously unaffordable": "5 times of annual income",
      "Severely unaffordable": ">5 times of annual income",
    },
    rent: {
      Affordable: "20% of weekly income",
      Acceptable: "25% of weekly income",
      "Moderately unaffordable": "30% of weekly income",
      Unaffordable: "40% of weekly income",
      "Severely unaffordable": "60% of weekly income",
      "Extremely unaffordable": ">60% of weekly income",
    },
  },
};
