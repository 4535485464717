import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import "react-tooltip/dist/react-tooltip.css";

import GeoJsonDataProvider from "./contexts/GeoJsonDataContext";
import SalariesProvider from "./contexts/SalariesContext";
import FilterProvider from "./contexts/FilterContext";
import DataProvider from "./contexts/DataContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GeoJsonDataProvider>
      <SalariesProvider>
        <FilterProvider>
          <DataProvider>
            <App />
          </DataProvider>
        </FilterProvider>
      </SalariesProvider>
    </GeoJsonDataProvider>
  </React.StrictMode>
);
