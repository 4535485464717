import React, { createContext, useState, useEffect } from "react";
import Papa from "papaparse";

export const SalariesContext = createContext();

const SalariesProvider = ({ children }) => {
  const [salariesData, setSalariesData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/salaries.csv");
      const reader = response.body.getReader();

      // Stream processing
      let chunks = [];
      let done, value;
      while (!done) {
        ({ done, value } = await reader.read());
        if (value) {
          chunks.push(value);
        }
      }

      // Decode the entire file using TextDecoder, which handles encoding correctly
      const decoder = new TextDecoder("utf-8");
      let csv = "";
      for (const chunk of chunks) {
        csv += decoder.decode(chunk, { stream: true });
      }
      // Finalize decoding
      csv += decoder.decode(); // To flush the decoder's internal state

      // Parse CSV with PapaParse
      const results = Papa.parse(csv, { header: true });
      setSalariesData(results.data);
    };

    fetchData();
  }, []);

  return (
    <SalariesContext.Provider value={salariesData}>
      {children}
    </SalariesContext.Provider>
  );
};

export default SalariesProvider;
