import { START_YEAR, CENSUS_YEARS } from "./config";

// Setting breakpoints based on the data’s standard deviation or interquartile range (IQR) can be particularly effective in creating bins that reflect the underlying distribution of the data, particularly in handling outliers.
// IQR Binning: The function calculates the quartiles and IQR to determine what is typical versus atypical in a more robust way against outliers, classifying values based on how they compare to the core middle 50% of data (between Q1 and Q3).
export const calculateIQRStats = (data) => {
  const q1 = data[Math.floor(data.length / 4)];
  const q3 = data[Math.floor((3 * data.length) / 4)];
  const iqr = q3 - q1;
  return {
    q1,
    q3,
    lowerFence: q1 - 1.5 * iqr,
    upperFence: q3 + 1.5 * iqr,
  };
};

export const findBinForValue = (data, value) => {
  const { q1, q3, lowerFence, upperFence } = calculateIQRStats(data);
  if (value < lowerFence) return "Much lower";
  if (value < q1) return "Lower";
  if (value <= q3) return "Normal";
  if (value <= upperFence) return "Higher";
  return "Much higher";
};

export const sumKeyword = (data, keyword) => {
  return Object.keys(data).reduce((acc, key) => {
    if (key.toLowerCase().includes(keyword.toLowerCase())) {
      return acc + data[key];
    }
    return acc;
  }, 0);
};

export const getSupply = (feature, filters, year) => {
  const supply =
    filters.supply &&
    feature.properties[year] &&
    feature.properties[year].supply
      ? feature.properties[year].supply[filters.supply.value]
      : null;
  return supply;
};

export const getDemand = (feature, filters, year) => {
  const demand =
    filters.demand &&
    feature.properties[year] &&
    feature.properties[year].demand
      ? feature.properties[year].demand[filters.demand.value]
      : null;
  return demand;
};

// Affordability
export const calculateAffordability = (
  feature,
  salariesData,
  filters,
  year
) => {
  const housingMode = getHousingMode(filters);
  const propertyCategory = filters.propertyCategory.label;
  const housingCost =
    feature.properties[year] && feature.properties[year].housing
      ? feature.properties[year].housing[propertyCategory][housingMode]
      : null;
  const stateName = feature.properties.STE_NAME21
    ? feature.properties.STE_NAME21
    : null;

  const salaries = salariesData.filter((d) => d.State === stateName);
  const salary =
    stateName && salaries.length > 0
      ? getMostRecentSalaries(year, salaries)[filters.income.value]
      : null;

  let affordability = null;
  if (housingCost && salary) {
    if (filters.cost.value === "rent") {
      affordability = housingCost / (salary / 52);
    } else {
      affordability = housingCost / salary;
    }
  }
  return affordability;
};

export const calculateMedianAffordability = (
  shortageData,
  locationData,
  salariesData,
  filters
) => {
  const years = getYears(locationData.properties);
  const yearAffordabilities = {};

  // Initialize an array for each year to hold the affordability values
  years.forEach((year) => {
    yearAffordabilities[year] = [];
  });

  // Iterate over each location and calculate affordability for each year
  shortageData.features.forEach((location) => {
    years.forEach((year) => {
      const affordability = calculateAffordability(
        location,
        salariesData,
        filters,
        year
      );
      if (affordability !== null && !isNaN(affordability)) {
        yearAffordabilities[year].push(affordability);
      }
    });
  });

  // Calculate the median affordability for each year
  const medianAffordabilities = {};
  Object.keys(yearAffordabilities).forEach((year) => {
    const affordabilities = yearAffordabilities[year];
    if (affordabilities.length > 0) {
      affordabilities.sort((a, b) => a - b);
      const midIndex = Math.floor(affordabilities.length / 2);
      medianAffordabilities[year] =
        affordabilities.length % 2 !== 0
          ? affordabilities[midIndex]
          : (affordabilities[midIndex - 1] + affordabilities[midIndex]) / 2;
    } else {
      medianAffordabilities[year] = null;
    }
  });

  return medianAffordabilities;
};

export const getCost = (feature, filters, year) => {
  const housingMode = getHousingMode(filters);
  const propertyCategory = filters.propertyCategory.label;
  const housingCost =
    feature.properties[year] &&
    feature.properties[year].housing &&
    feature.properties[year].housing[propertyCategory]
      ? feature.properties[year].housing[propertyCategory][housingMode]
      : null;
  return housingCost;
};

export const calculateMedianCost = (shortageData, locationData, filters) => {
  const years = getYears(locationData.properties); // Assuming getYears returns an array of years
  const yearCosts = {};

  // Initialize an array for each year to hold the costs
  years.forEach((year) => {
    yearCosts[year] = [];
  });

  // Iterate over each location and calculate cost for each year
  shortageData.features.forEach((location) => {
    years.forEach((year) => {
      const cost = getCost(location, filters, year); // Assuming getCost returns a numerical cost or null
      if (cost !== null && !isNaN(cost)) {
        yearCosts[year].push(cost);
      }
    });
  });

  // Calculate the median cost for each year
  const medianCosts = {};
  Object.keys(yearCosts).forEach((year) => {
    const costs = yearCosts[year];
    if (costs.length > 0) {
      const sortedCosts = costs.sort((a, b) => a - b);
      const midIndex = Math.floor(sortedCosts.length / 2);
      medianCosts[year] =
        sortedCosts.length % 2 !== 0
          ? sortedCosts[midIndex]
          : (sortedCosts[midIndex - 1] + sortedCosts[midIndex]) / 2;
    } else {
      medianCosts[year] = null;
    }
  });

  return medianCosts;
};

export const getSalary = (salariesData, stateName, filters, year) => {
  const grade = filters.income.value;
  const salaries = salariesData.filter(
    (d) => d.State === stateName && d[grade]
  );

  // Extract years and compute the closest year
  const yearDifferences = salaries.map((salary) => {
    const salaryYear = salary.Effective_date
      ? parseInt(salary.Effective_date.split("/")[2])
      : null; // Extract year from date
    return salaryYear
      ? {
          year: salaryYear,
          difference: Math.abs(salaryYear - year),
        }
      : null;
  });

  // Sort by smallest difference and, if tied, by closest and latest year

  yearDifferences
    // .filter((d) => d !== null)
    .sort((a, b) => {
      if (a.difference === b.difference) {
        return a.year - b.year; // If difference is the same, prefer the more recent year
      }
      return b.difference - a.difference;
    });
  const closestYear = yearDifferences.slice(-1).pop().year;
  // Filter salaries to find the last entry for the closest year
  const filteredSalaries = salaries.filter((salary) => {
    const salaryYear = salary.Effective_date
      ? parseInt(salary.Effective_date.split("/")[2])
      : null;
    return salaryYear === closestYear;
  });
  return filteredSalaries.length > 0
    ? parseInt(filteredSalaries[filteredSalaries.length - 1][grade])
    : null;
};

export const getMedianSalary = (locationData) => {
  const years = getYears(locationData.properties);
  return years.map((year) => {
    if ("income" in locationData.properties[year])
      return locationData.properties[year].income["Median Income"];
    return null;
  });
};

export const getStateMedianSalary = (locationData) => {
  const years = getYears(locationData.properties);
  return years.map((year) => {
    if ("income" in locationData.properties[year])
      return locationData.properties[year].income["State Median Weekly Earning"]
        ? parseInt(
            locationData.properties[year].income["State Median Weekly Earning"]
          ) * 52
        : null;
    return null;
  });
};

export const calculateMedianSupply = (shortageData, locationData, filters) => {
  const years = getYears(locationData.properties);
  const yearSupplies = {};

  // Initialize an array for each year to hold supply values
  years.forEach((year) => {
    yearSupplies[year] = [];
  });

  // Iterate over each location and calculate supply for each year
  shortageData.features.forEach((location) => {
    years.forEach((year) => {
      const supply = getSupply(location, filters, year); // Assuming getSupply returns a numerical supply or null
      if (supply !== null && !isNaN(supply)) {
        yearSupplies[year].push(supply);
      }
    });
  });

  // Calculate the median supply for each year
  const medianSupplies = {};
  Object.keys(yearSupplies).forEach((year) => {
    const supplies = yearSupplies[year];
    if (supplies.length > 0) {
      const sortedSupplies = supplies.sort((a, b) => a - b);
      const midIndex = Math.floor(sortedSupplies.length / 2);
      medianSupplies[year] =
        sortedSupplies.length % 2 !== 0
          ? sortedSupplies[midIndex]
          : (sortedSupplies[midIndex - 1] + sortedSupplies[midIndex]) / 2;
    } else {
      medianSupplies[year] = null;
    }
  });

  return medianSupplies;
};

export const calculateMedianDemand = (shortageData, locationData, filters) => {
  const years = getYears(locationData.properties);
  const yearDemands = {};

  // Initialize an array for each year to hold demand values
  years.forEach((year) => {
    yearDemands[year] = [];
  });

  // Iterate over each location and calculate demand for each year
  shortageData.features.forEach((location) => {
    years.forEach((year) => {
      const demand = getDemand(location, filters, year); // Assuming getDemand returns a numerical demand or null
      if (demand !== null && !isNaN(demand)) {
        yearDemands[year].push(demand);
      }
    });
  });

  // Calculate the median demand for each year
  const medianDemands = {};
  Object.keys(yearDemands).forEach((year) => {
    const demands = yearDemands[year];
    if (demands.length > 0) {
      const sortedDemands = demands.sort((a, b) => a - b);
      const midIndex = Math.floor(sortedDemands.length / 2);
      medianDemands[year] =
        sortedDemands.length % 2 !== 0
          ? sortedDemands[midIndex]
          : (sortedDemands[midIndex - 1] + sortedDemands[midIndex]) / 2;
    } else {
      medianDemands[year] = null;
    }
  });

  return medianDemands;
};

// Shortage

export const calculateShortage = (feature, filters, year) => {
  const nearestCensusYear = getNearestCensusYear(year, CENSUS_YEARS);
  const earliestCensusYear = Math.min(...CENSUS_YEARS);
  const latestCensusYear = Math.max(...CENSUS_YEARS);
  let supply = null;

  if (
    parseInt(year) < earliestCensusYear ||
    parseInt(year) > latestCensusYear
  ) {
    supply =
      feature.properties[nearestCensusYear] &&
      feature.properties[nearestCensusYear].supply
        ? feature.properties[nearestCensusYear].supply[filters.supply.value]
        : null;
  } else {
    // Check if the year is a direct census year
    // if (feature.properties.SA3_NAME_2021 === "Port Macquarie") debugger;
    const isCensusYear = CENSUS_YEARS.includes(year);
    if (isCensusYear) {
      supply =
        feature.properties[year] && feature.properties[year].supply
          ? feature.properties[year].supply[filters.supply.value]
          : null;
    } else {
      // Estimate supply for years within the census range but not on a census year
      const previousCensusYear = CENSUS_YEARS.filter((cy) => cy < year).pop();
      const nextCensusYear = CENSUS_YEARS.filter((cy) => cy > year)[0];

      if (
        previousCensusYear &&
        nextCensusYear &&
        feature.properties[previousCensusYear] &&
        feature.properties[nextCensusYear]
      ) {
        const previousSupply = feature.properties[previousCensusYear].supply
          ? feature.properties[previousCensusYear].supply[filters.supply.value]
          : null;
        const nextSupply = feature.properties[nextCensusYear].supply
          ? feature.properties[nextCensusYear].supply[filters.supply.value]
          : null;

        if (previousSupply !== null && nextSupply !== null) {
          // Calculate annual percentage change
          const yearsBetween = nextCensusYear - previousCensusYear;
          const rateOfChange =
            (nextSupply - previousSupply) / previousSupply / yearsBetween;
          // Estimate supply for the given year
          const yearsFromPrevious = year - previousCensusYear;
          supply = previousSupply * (1 + rateOfChange * yearsFromPrevious);
        } else {
          supply = null;
        }
      } else {
        supply = null;
      }
    }
  }

  const nearestDemand = getNearestDemand(year, feature);
  const demand = nearestDemand ? nearestDemand[filters.demand.value] : null;
  const shortage = supply && demand ? (demand - supply) / demand : null;

  return shortage;
};

export const calculateMedianShortage = (
  shortageData,
  locationData,
  filters
) => {
  const years = getYears(locationData.properties);
  const yearShortages = {};

  // Initialize an array for each year to hold the shortage values
  years.forEach((year) => {
    yearShortages[year] = [];
  });

  // Iterate over each location and calculate shortage for each year
  shortageData.features.forEach((location) => {
    years.forEach((year) => {
      const shortage = calculateShortage(location, filters, year);
      if (shortage !== null && !isNaN(shortage)) {
        yearShortages[year].push(shortage);
      }
    });
  });

  // Calculate the median shortage for each year
  const medianShortages = {};
  Object.keys(yearShortages).forEach((year) => {
    const shortages = yearShortages[year];
    if (shortages.length > 0) {
      shortages.sort((a, b) => a - b);
      const midIndex = Math.floor(shortages.length / 2);
      medianShortages[year] =
        shortages.length % 2 !== 0
          ? shortages[midIndex]
          : (shortages[midIndex - 1] + shortages[midIndex]) / 2;
    } else {
      medianShortages[year] = null;
    }
  });

  return medianShortages;
};

function getNearestCensusYear(year, censusYears) {
  if (censusYears.length === 0) {
    throw new Error("The censusYears array cannot be empty.");
  }

  let closestYear = censusYears[0];
  let smallestDifference = Math.abs(censusYears[0] - year);

  for (let i = 1; i < censusYears.length; i++) {
    const currentDifference = Math.abs(censusYears[i] - year);
    // Check if the current difference is smaller or if it's a tie and the current year is more recent
    if (
      currentDifference < smallestDifference ||
      (currentDifference === smallestDifference && censusYears[i] > closestYear)
    ) {
      closestYear = censusYears[i];
      smallestDifference = currentDifference;
    }
  }

  return closestYear;
}

function getNearestDemand(year, feature) {
  const properties = feature.properties;
  let closestYear = null;
  let minDifference = Infinity;

  // Iterate over each property in the feature's properties
  Object.keys(properties).forEach((key) => {
    // Check if the key is a valid year and has a 'demand' property
    if (key.match(/^\d{4}$/) && properties[key].demand !== undefined) {
      const difference = Math.abs(key - year);
      // Determine if this year is closer or if it's a tie and the year is more recent
      if (
        difference < minDifference ||
        (difference === minDifference && key > closestYear)
      ) {
        closestYear = key;
        minDifference = difference;
      }
    }
  });

  // Return the demand value of the closest year found
  return closestYear ? properties[closestYear].demand : null;
}

const getMostRecentSalaries = (year, salaries) => {
  if (salaries.length === 0) {
    throw new Error("The salaries array cannot be empty.");
  }

  let closestSalary = salaries[0]; // Start with the first salary as the closest
  let closestYear = parseInt(closestSalary.Effective_date.split("/")[2], 10); // Extract the year from the date
  let smallestDifference = Math.abs(closestYear - year); // Calculate the initial difference

  for (let i = 1; i < salaries.length; i++) {
    if (!salaries[i].Effective_date) debugger;
    const salaryYear = parseInt(salaries[i].Effective_date.split("/")[2], 10); // Extract the year from the date
    const currentDifference = Math.abs(salaryYear - year); // Calculate the difference

    // Determine if the current salary is closer or is equally close but more recent
    if (
      currentDifference < smallestDifference ||
      (currentDifference === smallestDifference && salaryYear > closestYear)
    ) {
      closestSalary = salaries[i];
      closestYear = salaryYear;
      smallestDifference = currentDifference;
    }
  }

  return closestSalary;
};

export const getYears = (obj) => {
  // Define the regular expression to match four consecutive digits
  const yearRegex = /^\d{4}$/;

  // Use Object.keys to get all keys, then filter using the regex
  return Object.keys(obj).filter(
    (key) => yearRegex.test(key) && parseInt(key) >= START_YEAR
  );
};

function getHousingMode(filters) {
  return filters.cost.value === "rent"
    ? "for_rent_home_lease_medianprice"
    : "for_sale_both_auction_private_treaty_medianprice";
}
